// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-component{
    width: 584px;
    height: 272px;
    flex-shrink: 0;
    gap: 26px;

    /* margin-top: 20px; */
    padding-left: 64px;    

    display: flex;
    align-items: center;


    border-radius: 50px;
    border: 5px solid var(--Blue_dark, #3062C8);
    background: #FBE9C1;

    transition: transform 0.3s ease;

}

.home-component:hover{
    /* background-color: #2980b9; */
    transform: scale(1.1);
    cursor: pointer;
}


.home-component-text{
    color: #262626;

    text-align: center;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/Components/~MolecularComponent/HomeComponent.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,SAAS;;IAET,sBAAsB;IACtB,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;;;IAGnB,mBAAmB;IACnB,2CAA2C;IAC3C,mBAAmB;;IAEnB,+BAA+B;;AAEnC;;AAEA;IACI,+BAA+B;IAC/B,qBAAqB;IACrB,eAAe;AACnB;;;AAGA;IACI,cAAc;;IAEd,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".home-component{\n    width: 584px;\n    height: 272px;\n    flex-shrink: 0;\n    gap: 26px;\n\n    /* margin-top: 20px; */\n    padding-left: 64px;    \n\n    display: flex;\n    align-items: center;\n\n\n    border-radius: 50px;\n    border: 5px solid var(--Blue_dark, #3062C8);\n    background: #FBE9C1;\n\n    transition: transform 0.3s ease;\n\n}\n\n.home-component:hover{\n    /* background-color: #2980b9; */\n    transform: scale(1.1);\n    cursor: pointer;\n}\n\n\n.home-component-text{\n    color: #262626;\n\n    text-align: center;\n    font-family: Poppins;\n    font-size: 50px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
