// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.academic-departments{
    display: flex;
    align-items: center;
    justify-content: center;
    gap : 25px;

    background-color: #f9f9f9;
    border : 1px solid red;
}`, "",{"version":3,"sources":["webpack://./src/Components/Body/HomeManager/~MolecularComponent/AcademicDepartments.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;;IAEV,yBAAyB;IACzB,sBAAsB;AAC1B","sourcesContent":[".academic-departments{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    gap : 25px;\r\n\r\n    background-color: #f9f9f9;\r\n    border : 1px solid red;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
