import React from 'react'
import Header from '../HomeProf/~MolecularComponent/Header'
import './Sessions.css'
    
const Sessions = () => {
    return (
        <div className='sessions'>
            <Header />
            <div>
                <h2>Les séances</h2>
            </div>
        </div>
      )
    }
    
export default Sessions