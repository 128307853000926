// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.form-container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    font-family: 'Crete Round', serif;
    font-size: 45px;
    color: #444;
}

.orange {
    color: #00BFFF;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 1rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    background-color: #343a40;
    color: #fff;
}

.table tbody + tbody {
    border-top: 2px solid #dee2e6;
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.action{
    display: flex;
    flex-direction: row;
    gap: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Body/Students/Students.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,sBAAsB;IACtB,gCAAgC;IAChC,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":["\n.form-container {\n    background-color: #ffffff;\n    padding: 30px;\n    border-radius: 10px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n}\n\nh1 {\n    font-family: 'Crete Round', serif;\n    font-size: 45px;\n    color: #444;\n}\n\n.orange {\n    color: #00BFFF;\n}\n\n.table {\n    width: 100%;\n    margin-bottom: 1rem;\n    color: #212529;\n    border-collapse: collapse;\n}\n\n.table th,\n.table td {\n    padding: 1rem;\n    vertical-align: top;\n    border-top: 1px solid #dee2e6;\n}\n\n.table thead th {\n    vertical-align: bottom;\n    border-bottom: 2px solid #dee2e6;\n    background-color: #343a40;\n    color: #fff;\n}\n\n.table tbody + tbody {\n    border-top: 2px solid #dee2e6;\n}\n\n.table-hover tbody tr:hover {\n    background-color: rgba(0, 0, 0, 0.075);\n}\n\n.table-striped tbody tr:nth-of-type(odd) {\n    background-color: rgba(0, 0, 0, 0.05);\n}\n\n.action{\n    display: flex;\n    flex-direction: row;\n    gap: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
