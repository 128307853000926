// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    border: 2px solid red;
}
.menu {
    display: flex;
    gap: 30px;
    list-style-type: none;
    padding: 0;
    text-align: center;
}
.menu li {
    margin: 15px 0;
}
.menu a {
    display: flex;
    justify-content: center;
    flex-direction: column;   
    gap: 10px;
    
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
    border: 1px solid #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}
.menu a:hover {
    background-color: #007bff;
    color: white;
}
.menu a:active {
    background-color: #0056b3;
    border-color: #004085;
}`, "",{"version":3,"sources":["webpack://./src/Components/Body/HomeManager/HomeManager.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,SAAS;IACT,qBAAqB;IACrB,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,SAAS;;IAET,qBAAqB;IACrB,cAAc;IACd,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,6CAA6C;AACjD;AACA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".container {\n    border: 2px solid red;\n}\n.menu {\n    display: flex;\n    gap: 30px;\n    list-style-type: none;\n    padding: 0;\n    text-align: center;\n}\n.menu li {\n    margin: 15px 0;\n}\n.menu a {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;   \n    gap: 10px;\n    \n    text-decoration: none;\n    color: #007bff;\n    font-size: 1.2rem;\n    border: 1px solid #007bff;\n    padding: 10px 20px;\n    border-radius: 5px;\n    transition: background-color 0.3s, color 0.3s;\n}\n.menu a:hover {\n    background-color: #007bff;\n    color: white;\n}\n.menu a:active {\n    background-color: #0056b3;\n    border-color: #004085;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
