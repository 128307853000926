// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-component{
    
    height: 60px;
    width: 900px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    border-radius: 10px;
    border: 2px solid red;
}`, "",{"version":3,"sources":["webpack://./src/Components/Body/Absence/~MolecularComponent/~AtomicComponent/StudentComponent.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,YAAY;;IAEZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;;IAET,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".student-component{\n    \n    height: 60px;\n    width: 900px;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 30px;\n\n    border-radius: 10px;\n    border: 2px solid red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
