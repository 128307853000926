// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-group-content{
    width: 80%;
    height: 617px;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    margin-left: 10%;
    border-radius: 15px;
    border: 4px solid var(--Yellow, #FFBB24);
    background: var(--White, #FAFAFA);
}

.group-inputs {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
  }
  
.group-inputs label {
    display: block;
    margin-bottom: 10px;
  }
  
.group-inputs input {
    padding: 8px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Body/HomeManager/~MolecularComponent/CreateGroup.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,cAAc;;IAEd,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;;IAEnB,gBAAgB;IAChB,mBAAmB;IACnB,wCAAwC;IACxC,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,mBAAmB;EACrB;;AAEF;IACI,cAAc;IACd,mBAAmB;EACrB;;AAEF;IACI,YAAY;IACZ,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;EACpB","sourcesContent":[".create-group-content{\n    width: 80%;\n    height: 617px;\n    flex-shrink: 0;\n\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n\n    margin-left: 10%;\n    border-radius: 15px;\n    border: 4px solid var(--Yellow, #FFBB24);\n    background: var(--White, #FAFAFA);\n}\n\n.group-inputs {\n    display: flex;\n    justify-content: center;\n    gap: 30px;\n    margin-bottom: 20px;\n  }\n  \n.group-inputs label {\n    display: block;\n    margin-bottom: 10px;\n  }\n  \n.group-inputs input {\n    padding: 8px;\n    margin-left: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
