import React from 'react'
import Header from '../HomeProf/~MolecularComponent/Header'
import './Notes.css'

const Notes = () => {
  return (
    <div className='notes'>
        <Header/>
        Notes
    </div>
  )
}

export default Notes