// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home{
    /* position: absolute; */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* border: red solid 2px; */
}

.home-title{
    margin-top: 147px;
    color: var(--Primary, #246BFE);
    font-family: Preahvihear;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* border: red solid 2px; */
}

.home-description{
    margin-top: 51px;
    width: 707px;
    height: 139px;
    flex-shrink: 0;
    
    color: var(--Yellow, #FFBB24);
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
    
    /* border: red solid 2px; */
}

.home-button{
    margin-top: 51px;
    display: flex;
    gap: 189px;
    /* border: red solid 2px; */
}`, "",{"version":3,"sources":["webpack://./src/Components/Body/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;;IAExB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;IAEvB,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;IACjB,8BAA8B;IAC9B,wBAAwB;IACxB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,cAAc;;IAEd,6BAA6B;IAC7B,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;;;IAGnB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,UAAU;IACV,2BAA2B;AAC/B","sourcesContent":[".home{\n    /* position: absolute; */\n    \n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    /* border: red solid 2px; */\n}\n\n.home-title{\n    margin-top: 147px;\n    color: var(--Primary, #246BFE);\n    font-family: Preahvihear;\n    font-size: 70px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    /* border: red solid 2px; */\n}\n\n.home-description{\n    margin-top: 51px;\n    width: 707px;\n    height: 139px;\n    flex-shrink: 0;\n    \n    color: var(--Yellow, #FFBB24);\n    font-family: Poppins;\n    font-size: 40px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    \n    \n    /* border: red solid 2px; */\n}\n\n.home-button{\n    margin-top: 51px;\n    display: flex;\n    gap: 189px;\n    /* border: red solid 2px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
