// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.square-icon{
    width: 150px;
    height: 166.304px;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;



    border-radius: 15px;
    border: 3px solid var(--Yellow, #FFBB24);
    background: var(--Blue_light, #B4D6F6);
}

.square-icon img {
    width: 70%;
    height: 70%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/~MolecularComponent/~AtomicComponent/SquareIcon.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,cAAc;;IAEd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;;;IAIvB,mBAAmB;IACnB,wCAAwC;IACxC,sCAAsC;AAC1C;;AAEA;IACI,UAAU;IACV,WAAW;AACf","sourcesContent":[".square-icon{\n    width: 150px;\n    height: 166.304px;\n    flex-shrink: 0;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n\n\n    border-radius: 15px;\n    border: 3px solid var(--Yellow, #FFBB24);\n    background: var(--Blue_light, #B4D6F6);\n}\n\n.square-icon img {\n    width: 70%;\n    height: 70%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
