// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.academic-departments-component{
    width: 150px;
    height: 150px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 15px;
    background-color: #B4D6F6;
    border: 2px solid #FFBB24 ;
}`, "",{"version":3,"sources":["webpack://./src/Components/Body/HomeManager/~MolecularComponent/~AtomicComponent/AcademicDepartmentsComponent.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;;IAEb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,mBAAmB;IACnB,yBAAyB;IACzB,0BAA0B;AAC9B","sourcesContent":[".academic-departments-component{\r\n    width: 150px;\r\n    height: 150px;\r\n    \r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    border-radius: 15px;\r\n    background-color: #B4D6F6;\r\n    border: 2px solid #FFBB24 ;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
