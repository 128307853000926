// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-button{
    width: 300px;
    /* height: 158px; */
    flex-shrink: 0;
 
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 9px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 15px;
    background: #B4D6F6;
}`, "",{"version":3,"sources":["webpack://./src/Components/~MolecularComponent/ListButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,cAAc;;IAEd,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;IAEnB,QAAQ;IACR,iBAAiB;IACjB,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".list-button{\n    width: 300px;\n    /* height: 158px; */\n    flex-shrink: 0;\n \n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    gap: 9px;\n    padding-top: 15px;\n    padding-bottom: 15px;\n    border-radius: 15px;\n    background: #B4D6F6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
