// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.school-subjects{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.school-subjects-components{
    gap : 25px;
    display: flex;
    text-align: center;

    border : 2px red solid;

}`, "",{"version":3,"sources":["webpack://./src/Components/Body/SchoolSubjects/SchoolSubjects.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,kBAAkB;;IAElB,sBAAsB;;AAE1B","sourcesContent":[".school-subjects{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.school-subjects-components{\n    gap : 25px;\n    display: flex;\n    text-align: center;\n\n    border : 2px red solid;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
