// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-button-component{
    width: 249px;
    height: 45px;
    flex-shrink: 0;

    gap : 19px;
    padding-left: 8px;
    display: flex;
    align-items: center;

    border-radius: 15px;
    background: var(--White, #FAFAFA);

    transition: transform 0.3s ease;
}

.list-button-component:hover{
    transform: scale(1.1);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/~MolecularComponent/~AtomicComponent/ListButtonComponent.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;;IAEd,UAAU;IACV,iBAAiB;IACjB,aAAa;IACb,mBAAmB;;IAEnB,mBAAmB;IACnB,iCAAiC;;IAEjC,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[".list-button-component{\n    width: 249px;\n    height: 45px;\n    flex-shrink: 0;\n\n    gap : 19px;\n    padding-left: 8px;\n    display: flex;\n    align-items: center;\n\n    border-radius: 15px;\n    background: var(--White, #FAFAFA);\n\n    transition: transform 0.3s ease;\n}\n\n.list-button-component:hover{\n    transform: scale(1.1);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
