// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    position: relative;
    width: 100%;
    height: 99px;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    
    z-index: 4;

    background: var(--Primary, #246BFE);

}

.header-logo{
    left: 30px;

    gap: 28px;
    display: flex;
    align-items: center;
}

.header-logo img{
    cursor: pointer;
}

.header-icons{
    position: absolute;
    right: 26px;
    gap: 18px;
    display: flex;
    align-items: center;   

    border: red solid 2px;

}

.header-icons .square-icon{
    transition: transform 0.3s ease;
}

.header-icons .square-icon:hover{
    transform: scale(1.1);
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/Components/Body/HomeProf/~MolecularComponent/Header.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,cAAc;;IAEd,aAAa;IACb,mBAAmB;;IAEnB,UAAU;;IAEV,mCAAmC;;AAEvC;;AAEA;IACI,UAAU;;IAEV,SAAS;IACT,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,aAAa;IACb,mBAAmB;;IAEnB,qBAAqB;;AAEzB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[".header{\n    position: relative;\n    width: 100%;\n    height: 99px;\n    flex-shrink: 0;\n\n    display: flex;\n    align-items: center;\n    \n    z-index: 4;\n\n    background: var(--Primary, #246BFE);\n\n}\n\n.header-logo{\n    left: 30px;\n\n    gap: 28px;\n    display: flex;\n    align-items: center;\n}\n\n.header-logo img{\n    cursor: pointer;\n}\n\n.header-icons{\n    position: absolute;\n    right: 26px;\n    gap: 18px;\n    display: flex;\n    align-items: center;   \n\n    border: red solid 2px;\n\n}\n\n.header-icons .square-icon{\n    transition: transform 0.3s ease;\n}\n\n.header-icons .square-icon:hover{\n    transform: scale(1.1);\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
