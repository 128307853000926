import React from 'react'
import './Classe.css'

const Classe = (props) => {
    return (
    <div className='classe' onClick={props.onComponentClick}>
        1A
    </div>
  )
}

export default Classe