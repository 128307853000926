// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.absence{
    
}

.absence-title{
    margin-top: 40px;
    position: relative;
    margin-left: 20px;
    display: flex;
    align-items: center;
    gap : 20px;

    border: 2px solid red;
}

.absence-title-text{
    color: var(--Blue_dark, #3062C8);
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.absence-content{
    width: 80%;
    height: 617px;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    /* align-items: center; */

    margin-left: 10%;
    border-radius: 15px;
    border: 4px solid var(--Yellow, #FFBB24);
    background: var(--White, #FAFAFA);
}`, "",{"version":3,"sources":["webpack://./src/Components/Body/Absence/Absence.css"],"names":[],"mappings":"AAEA;;AAEA;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,UAAU;;IAEV,qBAAqB;AACzB;;AAEA;IACI,gCAAgC;IAChC,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,cAAc;;IAEd,aAAa;IACb,uBAAuB;IACvB,yBAAyB;;IAEzB,gBAAgB;IAChB,mBAAmB;IACnB,wCAAwC;IACxC,iCAAiC;AACrC","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');\n\n.absence{\n    \n}\n\n.absence-title{\n    margin-top: 40px;\n    position: relative;\n    margin-left: 20px;\n    display: flex;\n    align-items: center;\n    gap : 20px;\n\n    border: 2px solid red;\n}\n\n.absence-title-text{\n    color: var(--Blue_dark, #3062C8);\n    font-family: Poppins;\n    font-size: 40px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}\n\n.absence-content{\n    width: 80%;\n    height: 617px;\n    flex-shrink: 0;\n\n    display: flex;\n    justify-content: center;\n    /* align-items: center; */\n\n    margin-left: 10%;\n    border-radius: 15px;\n    border: 4px solid var(--Yellow, #FFBB24);\n    background: var(--White, #FAFAFA);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
